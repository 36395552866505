<template>
  <v-card class="mt-n10 moholand-shadow">
    <v-card-title>
      ایجاد پست جدید
      <v-spacer></v-spacer>

      <v-btn color="primary" :to="destinationRoute">
        <v-icon>mdi-undo-variant</v-icon>
        بازگشت
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" @submit.prevent="submit">
        <v-form-base
          :col="{ cols: 12, md: 6 }"
          :model="model"
          :schema="schema"
        />
        <v-row align="center" justify="end" class="mt-8">
          <v-btn @click="clear" depressed>
            پاک کردن فرم
          </v-btn>

          <v-btn
            class="mx-4"
            dark
            color="success"
            :loading="loading"
            type="submit"
          >
            ایجاد پست جدید
          </v-btn>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import VFormBase from "vuetify-form-base";
import { formPostPrepare } from "@/constants/PanelFormHandler";
export default {
  components: {
    VFormBase,
  },
  data() {
    return {
      rules: Constants.rules,
      destinationRoute:
        this.$route.fullPath.slice(
          0,
          this.$route.fullPath.indexOf("create") - 1
        ) + (this.$route.query?.page ? "?page=" + this.$route.query?.page : ""),
      loading: false,
      model: {
        title: "",
        desc: "",
        user_id: this.$store.state.userData.id || 0,
        category_id: "",
        media_id: "",
      },
      schema: {
        title: {
          type: "CustomInput",
          label: "نام پست",
          rules: [Constants.rules.required],
          required: true,
          col: { cols: 12, md: 4 },
        },
        category_id: {
          type: "CustomInput",
          inputType: "autocomplete",
          items: [],
          itemValue: "id",
          itemText: "value",
          label: "دسته بندی",
          required: true,
          loading: true,
          col: { cols: 12, md: 4 },
        },
        media_id: {
          type: "MyFilePond",
          title: "فایل",
          rules: [Constants.rules.required],
          required: true,
          col: { cols: 12, md: 4 },
        },
        desc: {
          type: "MyTinyMCE",
          label: "توضیحات",
          required: true,
          col: { cols: 12 },
        },
      },
    };
  },
  created() {
    this.getCategories();
  },

  methods: {
    getCategories() {
      MyAxios.get("/categories/index", {
        params: {
          noPaginate: true,
          conditions: {
            parent_id: 8,
          },
        },
      })
        .then((response) => {
          this.schema.category_id = {
            ...this.schema.category_id,
            loading: false,
            items: response.data.map((val) => ({
              id: val.id,
              value: val.name,
            })),
          };
        })
        .catch((error) => {
          console.log("laws", error.response);
        });
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        let data = formPostPrepare(this.model);
        data.append("slug", this.model.title.replace(/\/|\s|\./g, "-"));

        MyAxios.post("posts/create", data)
          .then((response) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: "پست با موفقیت ایجاد شد.",
              type: "success",
            });
            console.log(response);
            this.$router.replace(this.destinationRoute);
          })
          .catch((error) => {
            this.loading = false;
            if (
              error?.response?.data?.message
                ?.toLowerCase()
                ?.includes("duplicate")
            )
              this.$root.$emit("toast", {
                text:
                  "اسلاگ تکراری است. لطفا در نامگذاری مقاله دقت کنید. اسلاگ همان نام پست میباشد.",
              });
            else
              this.$root.$emit("toast", {
                text: "مشکلی پیش آمده است. لطفا دوباره سعی کنید",
              });
            console.log(error?.response?.data?.message);
          });
      } else {
        this.$root.$emit("toast", {
          text: "لطفا اطلاعات را به درستی وارد نمایید.",
        });
      }
    },
    clear() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style></style>
